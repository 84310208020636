@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	&[data-theme='dark'] {
		background-color: $background-black;
		color: $text-white;

		.eyebrow {
			color: $text-grey-light;
		}
	}

	&[data-theme='light'] {
		background-color: $background-off-white;
		color: $text-black;

		.subheader {
			color: $text-grey-dark;
		}

		.eyebrow {
			color: $text-grey-dark;
		}
	}

	&[data-theme='white'] {
		background-color: $background-white;
		color: $text-black;

		.subheader {
			color: $text-grey-dark;
		}

		.eyebrow {
			color: $text-grey-dark;
		}
	}

	@include section-padding;
}

.wrapper {
	max-width: 90rem;
	margin-inline: auto;

	display: grid;
	grid-template-columns: 4fr 6fr;
	align-items: center;

	gap: $spacing-x-large-desktop;

	@include sm-down {
		grid-template-columns: 1fr;
	}
}

.header {
	margin-top: $spacing-xx-small-desktop;
	max-width: 15ch;
}

.subheader {
	max-width: 30ch;
}

.cta {
	margin-top: $spacing-large-desktop;
}

.image_container {
	border-radius: $spacing-xx-small-desktop;
	contain: paint;

	position: relative;

	img {
		height: 23rem;
	}

	@include sm-down {
		img {
			height: 18rem;
		}
	}

	@include xs-only {
		img {
			height: 14rem;
		}
	}
}

.image_tag {
	position: absolute;
	bottom: $spacing-medium-desktop;
	left: $spacing-x-large-desktop;

	color: $text-white;
}

.financing_tag {
	display: flex;
	align-items: center;
}

.financing_tag {
	gap: 0.5em;
	svg {
		height: 1.7em;
	}
}
