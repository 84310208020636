@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	background-color: $black;
	color: $white;

	@include sm-down {
		height: 40rem;
	}
}

.image {
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	img {
		width: 100%;
		height: 100%;
		object-position: center 28%;
	}

	@include sm-down {
		img {
			object-position: 35% center;
		}
		&::after {
			content: '';

			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 36.7%, #000 63.65%);

			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	height: 100%;

	position: relative;
	isolation: isolate;

	text-align: center;

	padding: 20rem $spacing-md;

	@include sm-down {
		padding: 5rem 2rem;
		justify-content: flex-end;
	}

	p {
		text-align: center;

		max-width: 22ch;
	}
}

.header_cta {
	margin-top: 2rem;
}
