@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	&[data-theme='light'] {
		background-color: $background-off-white;
		color: $text-black;

		.award_separator::after {
			background: $text-grey-light;
		}

		.award_year {
			color: $text-grey-dark;
		}
	}

	&[data-theme='dark'] {
		background-color: $background-black;
		color: $text-white;

		.award_separator::after {
			background: $border-grey-light;
		}

		.award_year {
			color: $text-grey-light;
		}
	}

	@include section-padding-small;
	// border-bottom: 1px solid $border-grey-dark;
}

.awards_list {
	margin-block: 0;

	list-style: none;
	display: flex;

	@include sm-down {
		.award_separator {
			display: none;
		}

		display: grid;
		grid-template-columns: repeat(2, 1fr);

		row-gap: 4rem;
		column-gap: 1rem;
		justify-content: space-between;
		justify-items: center;
	}

	.awards_wrapper {
		@include sm-down {
			gap: 1rem;
			grid-template-columns: 1fr;
			grid-template-rows: auto 1fr;
			align-items: start;
		}
	}

	.award_logo {
		@include sm-down {
			margin-inline: 0;
		}
	}
}

.awards_wrapper {
	display: grid;
	grid-template-columns: auto 1fr;

	align-items: center;

	padding: 1.25rem 0;
}

.award_logo {
	border-radius: $br-circle;
	background-color: $background-white;

	display: flex;
	justify-content: center;
	align-items: center;

	margin-inline: 1rem;

	$dim: 4.375rem;
	width: $dim;
	height: $dim;

	padding: 0.5rem;

	svg {
		height: 1rem;
		width: auto;
	}
}

.award_title {
	max-width: 12ch;
}

.award_separator {
	margin-inline: auto;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		width: 1px;
		height: 100%;
	}
}
